body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}

.line-form {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.fGfull{
  flex-grow: 1;
  padding-right: 1rem;
}
.fG23{
  width: 75%;
  padding-right: 1rem;
}

.alerte-input{
  font-size: 14px;
  font-weight: bold;
  color: red;
  margin: 0 0 0 8px;
  padding: 0;
  font-style: italic;
}

.one-num-rotation {
  border-radius: 50%;
  margin: 3px;
  width: 26px;
  height: 26px;
  color: white;
  background: #cb1010;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
  transition: transform 0.3s;
}
.one-num-rotation.isAdd{
   color:black;
 }
.one-num-rotation.isSelected{
   transform: scale(1.2);
 }
.one-num-rotation svg{
  margin: auto;
}
.one-num-rotation:hover{
   transform: scale(1.2);
   transition: transform 0.3s;
   cursor: pointer;
 }

.content-dash{
  max-width: 1280px;
  min-width: 880px;
  min-height: 100%;
  width: 90%;

   margin: auto;
  /*background: #cee5f8;*/
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.wrap-column-dash{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}
.wrap-column-dash.w-20{
  width: 20%;
}
.wrap-column-dash.w-30{
  width: 30%;
}
.wrap-column-dash.w-35{
  width: 35%;
}
.wrap-column-dash.w-40{
  width: 40%;
}
.wrap-column-dash.w-50{
  width: 50%;
}
.wrap-column-dash.w-75{
  width: 75%;
}
.wrap-column-dash.w-40{
  width: 40%;
}
.wrap-column-dash.w-25{
  width: 25%;
}
.wrap-column-dash.w-20{
  width: 20%;
}
.wrap-column-dash.w-60{
  width: 60%;
}
.wrap-column-dash.w-70{
  width: 70%;
}

.wrap-line-dash{
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  gap: 32px;
  align-content: stretch;
  align-items: stretch;
  flex-shrink: 0;
}

.fine-scroll::-webkit-scrollbar{
  width: 3px;
}
.fine-scroll::-webkit-scrollbar-thumb{
  width: 3px;

}

.modal-two-part{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  visibility: hidden;
  height: 100%;
  z-index: 990;
  transition: width 0.3s;
  visibility: visible;
}
.modal-two-part .back-shadow{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0,0,0,.6);
  width: 100%;
  height: 100%;
}
.modal-two-part .modal-in{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 97%;
  z-index: 2;
  background: transparent;
  display: flex;
  justify-content: flex-end;
}

.modal-two-part .modal-in .part-left{
  background: white;
  width: 70%;
  height: 100%;
  padding: 0.5rem;
}

.modal-two-part .modal-in .part-right{
  background: white;
  width: 30%;
  height: 100%;
  box-shadow: -1px 0 3px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.modal-two-part .modal-in .part-right .head-right{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.modal-two-part .modal-in .part-right .body-right{
  flex-grow: 1;
  flex-shrink: 1;
  height: 0;
  padding: 1rem;
}

.modal-two-part .modal-in .part-right .head-right .close-modal{
  padding: 0.2rem;
}
.modal-two-part .modal-in .part-right .head-right .close-modal .content-close{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.modal-two-part .modal-in .part-right .head-right .close-modal .content-close:hover{
  cursor: pointer;
}

.element_smart_nav{
  background: white;
  width: 25%;
  padding: 4px;
}
.content-smart{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

